<template>
    <section class="contract">
        <div v-if="zUuid || ziList.length === 0">
            <div style="text-align: right">
                <!--2022/03/21 王江毅 右上角 添加杂费账单-->
                <el-link type="primary" :underline="false" v-if="!isSign" @click="openDialog">添加租房合同</el-link>
            </div>
            <div class="table-title">租房合同记录</div>
            <r-e-table ref="communityTableRef" :dataRequest="getContractTableData" :columns="houseContractColumns"
                       :height="700">
                <el-table-column slot="toolbar" label="操作">
                    <template slot-scope="{ row, row: { contractStatusCode: code } }">
                        <div class="table-tools">
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                  操作<i class="el-icon-arrow-down el-icon--right"/>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="handleClickDetail(row)">
                                        <span class="table-btn">查看详情</span>
                                    </el-dropdown-item>
                                    <!-- 2023/08/22 张晓瑜新增按钮 -->
                                    <el-dropdown-item @click.native="contractFiling(row)">
                                        <span class="table-btn">合同备案</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="lookBills(row)">
                                        <span class="table-btn">查看账单</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="code === 500709" @click.native="perfectTheContract(row)">
                                        <span class="table-btn">完善合同</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="code === 500701 || code === 500702 || code === 500704"
                                                      @click.native="xuqian(row)">
                                        <span class="table-btn">续租</span>
                                    </el-dropdown-item>
                                    <!-- 2024/04/23 张晓瑜切换将换房跳转改为退房 -->
                                    <el-dropdown-item v-if="code === 500701" @click.native="tuifang(row)">
                                        <span class="table-btn">换房</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="code === 500701 || code === 500702"
                                                      @click.native="tuifang(row)">
                                        <span class="table-btn">退房</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="code === 500701 ||code === 500704 ||code === 500706 ||code === 500709"
                                        @click.native="zuofei(row)">
                                        <span class="table-btn">作废</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="collection(row)">
                                        <span class="table-btn">催收</span>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
        <div v-else>
            <div style="text-align: right">
                <!--2022/03/21 王江毅 右上角 添加杂费账单-->
                <!--                <el-link type="primary" :underline="false" v-if="!isSign" @click="openDialog">添加租房合同</el-link>-->
            </div>
            <div class="table-title">租房合同记录</div>
            <r-e-table ref="communityTableRef" :dataRequest="getSonAllList" :columns="houseContractBzfColumns"
                       :height="700">
                <el-table-column slot="toolbar" label="操作">
                    <template slot-scope="{ row, row: { contractStatusCode: code } }">
                        <div class="table-tools">
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                  操作<i class="el-icon-arrow-down el-icon--right"/>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="handleClickDetail(row)">
                                        <span class="table-btn">查看详情</span>
                                    </el-dropdown-item>
                                    <!-- 2023/08/22 张晓瑜新增按钮 -->
                                    <el-dropdown-item @click.native="contractFiling(row)">
                                        <span class="table-btn">合同备案</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="lookBills(row)">
                                        <span class="table-btn">查看账单</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="code === 500709" @click.native="perfectTheContract(row)">
                                        <span class="table-btn">完善合同</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="code === 500701 || code === 500702 || code === 500704"
                                        @click.native="xuqian(row)">
                                        <span class="table-btn">续租</span>
                                    </el-dropdown-item>
                                    <!-- 2024/04/23 张晓瑜切换将换房跳转改为退房 -->
                                    <el-dropdown-item v-if="code === 500701" @click.native="tuifang(row)">
                                        <span class="table-btn">换房</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="code === 500701 || code === 500702"
                                        @click.native="tuifang(row)">
                                        <span class="table-btn">退房</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="code === 500701 || code === 500704 || code === 500706 || code === 500709"
                                        @click.native="zuofei(row)">
                                        <span class="table-btn">作废</span>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="collection(row)">
                                        <span class="table-btn">催收</span>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>

        <r-e-dialog :title="dialogTitle" class="layer-contract-detail" :visible.sync="dialogVisible"
                    :show-footer="false" top="10vh">
        <!--合同详情内容入口-->
            <r-e-descriptions :column="2" :schemes="houseLesseeSchemas" :data="contractInfo"
                              :labelStyle="{ width: '80px', textAlign: 'right', display: 'inline-block',}">
                <div class="title" slot="title">承租人信息</div>
            </r-e-descriptions>

            <r-e-descriptions :column="2" :schemes="houseContractSchemas" :data="contractInfo"
                              :labelStyle="{ width: '80px', textAlign: 'right', display: 'inline-block', }"
            >
                <div class="title" slot="title">合同信息</div>
            </r-e-descriptions>
            <!-- 身份证弹框 -->
            <dilalogImg ref="imgref"/>
        </r-e-dialog>

        <!-- 2022/04/25 王江毅 添加合同dialog -->
        <dialog-add-contract ref="dialogAddContract" :currentHouse="currentHouse" @handleSearch="handleSearch"
                             :zUuid="zUuid"/>
        <!-- 2022/04/25 王江毅 添加合同附件dialog -->
        <dialog-save-contract-file ref="dialogSaveContractFile" :currentHouse="currentHouse"
                                   :contractInfo="contractInfo"
                                   @getContractDetailData="getContractDetailData"/>
        <!-- 2022/04/25 王江毅 查看合同附件dialog -->
        <dialog-look-contract-file ref="dialogLookContractFile"/>
        <!-- 2022/04/25 王江毅 退房dialog -->
        <dialog-check-out ref="dialogCheckOut" :currentHouse="currentHouse" @handleSearch="handleSearch"/>
        <!-- 2022/04/25 王江毅 续租dialog -->
        <dialog-contract-xuqian ref="dialogContractXuqian" :currentHouse="currentHouse" @handleSearch="handleSearch"/>
        <!-- 2022/05/09 王江毅 换房dialog -->
        <dialog-exchange-houses ref="dialogExchangeHouses" :currentHouse="currentHouse" @handleSearch="handleSearch"/>
        <!-- 2022/05/09 王江毅 同住人dialog -->
        <dialog-tongzhuren-list ref="dialogTongzhurenList" @getContractDetailData="getContractDetailData"/>
        <!-- 2022/05/16 查看账单dialog -->
        <dialog-look-zhangdan ref="dialogLookZhangdan"/>
        <!-- 2022/08/22 张晓瑜 合同备案dialog  -->
        <dialog-contract-filing ref="dialogContractFiling"/>

        <!-- 2022/05/09 王江毅 合同备注dialog -->
        <r-e-dialog title="合同备注" class="layer-contract-detail" :visible.sync="dialogVisibleComment" show-footer
                    top="10vh" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="65px" size="small" :model="formPublish" :rules="rules">
                <el-form-item label="备注" prop="comment">
                    <el-input v-model="formPublish.comment" type="textarea" :autosize="{ minRows: 3, maxRows: 6 }"
                              style="width: 95%" placeholder="请填写合同备注"/>
                </el-form-item>
            </el-form>
        </r-e-dialog>

        <el-dialog title="催收" :visible.sync="collectionDialogVisible" width="30%" :before-close="collectionClickCancel">
            <span>
                <el-radio-group v-model="collectionData.type">
                    <el-radio :label="0">小程序通知</el-radio>
                    <el-radio :label="1">短信通知</el-radio>
                </el-radio-group>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="collectionClickCancel">取 消</el-button>
                <el-button type="primary" @click="collectionClickSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </section>
</template>

<script>
import * as houseData from "@/views/rental-management/house-resource/data";
import * as houseApi from "@/api/house-resource";
import {
    nullify, editComment, getApartmentData, contractSign, contractIsSign, getSonAllList, bzfInvalid,
    bzfContractInvalid, getAssetsverifyApi,getIsRenovationApi
} from "@/api/contract";
import {MessageSuccess} from "@custom/message";
import {messageSend} from "@/api/message";
import {isbzf} from "@/api/house-resource";

export default {
    name: "contract",
    data() {
        return {
            houseContractColumns: houseData.houseContractColumns(this),
            houseContractBzfColumns: houseData.houseContractBzfColumns(this),
            houseLesseeSchemas: houseData.houseLesseeSchemas(this),
            houseContractSchemas: houseData.houseContractSchemas(this),
            contractInfo: {},
            dialogVisible: false,
            dialogTitle: "租房合同详情",
            paymentTypeList: [],
            formPublish: {
                uuid: "",
                comment: "",
            },
            rules: {
                comment: [{required: true, message: "请输入备注", trigger: "blur"}],
            },
            dialogVisibleComment: false,
            loadingOptions: {
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            },
            collectionDialogVisible: false,
            collectionData: {
                uuid: null,
                type: 0,
            },

            isSign: true,

            isbzf: false, //是否是保租房 true是，false不是
        };
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({}),
        },
        zUuid: {
            type: String,
            default: null,
        },
        ziList: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        dialogAddContract: () => import("./components/dialog-add-contract"),
        dialogSaveContractFile: () => import("./components/dialog-save-contract-file"),
        dialogLookContractFile: () => import("./components/dialog-look-contract-file"),
        dialogCheckOut: () => import("./components/dialog-check-out"),
        dialogContractXuqian: () => import("./components/dialog-contract-xuqian"),
        dialogExchangeHouses: () => import("./components/dialog-exchange-houses"),
        dialogTongzhurenList: () => import("./components/dialog-tongzhuren-list"),
        dialogLookZhangdan: () => import("./components/dialog-look-zhangdan"),
        dialogContractFiling: () => import("./components/dialog-contract-filing"),
        dilalogImg: () => import("./components/dilalog-img"),
    },
    async created() {
        const {apartmentUuid} = this.currentHouse;
        let uuid = this.zUuid || apartmentUuid;
        this.paymentTypeList = await this.$store.dispatch("app/getDictionaryByCodeActions", "500100");
        this.paymentTypeList = this.paymentTypeList.filter((item) => item.code !== 500101 && item.code !== 500102 && item.code !== 500106 && item.code !== 500107);
        let {info} = await contractIsSign(uuid);
        this.isSign = info;
        const s1 = await isbzf(uuid);
        this.isbzf = !s1.info;
    },
  mounted() {
    // var self = this
    // document.addEventListener("visibilitychange", function() {
    //   if (document.visibilityState == "hidden") {
    //     //切离该页面时执行
    //   } else if (document.visibilityState == "visible") {
    //     //切换到该页面时执行   跨页面响应-陈守亮-2024-1-5-陈守亮
    //     self.handleSearch();
    //   }
    // });

  },
  methods: {

        // 获取房源所有合同(无子房间)
        getContractTableData(params) {
            // 获取房源uuid
            const {apartmentUuid} = this.currentHouse;
            params.uuid = this.zUuid || apartmentUuid;
            return houseApi.getHouseContractApi(params);
        },
        // 获取房源所有合同(有子房间)
        getSonAllList(params) {
            // 获取房源uuid
            const {apartmentUuid} = this.currentHouse;
            params.uuid = apartmentUuid;
            return getSonAllList(params);
        },
        // 获取合同详情
        async getContractDetailData(uuid) {
            try {
                // 获取合同详情
                const {info} = await houseApi.getHouseContractDetailApi(uuid);
              console.log(info)
                this.contractInfo = info;
            } catch (e) {
                console.error("获取合同详情", e);
            }
        },

        //打开合同详情弹框
        handleClickDetail(row) {
            // 打开详情弹窗
            this.dialogVisible = true;
            this.dialogTitle = `${row.leasorName}租房合同详情`;
            this.getContractDetailData(row.uuid);
        },

        //刷新合同列表
        handleSearch() {
            this.$refs["communityTableRef"].getTableData();
        },

        //合同作废
        zuofei(data) {
            let that = this;
            that.$confirm("此操作将作废该合同, 是否继续?", "注意", {type: "warning"}).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                nullify(data).then((res) => {
                    MessageSuccess("合同作废成功");
                    if (that.isbzf) {
                        //作废诺诺电子合同
                        bzfContractInvalid(data.uuid);
                    }
                    that.handleSearch();
                }).finally(() => loading.close());
            }).catch(() => {});
        },

        // 点击添加合同事件
        async openDialog() {
            const {apartmentUuid} = this.currentHouse;
            const uuid = this.zUuid || apartmentUuid;
            const loadingOptions = this.loadingOptions;
            const loading = this.$loading({...loadingOptions});
            // 2024/张晓瑜新增加该房间是否是装修状态
            const res = await getIsRenovationApi(uuid).finally(() => loading.close());
            console.log('res',res );
            if (res.info) {
                this.$confirm('此房间当前处于装修状态，请确认是否发起合同' , {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(async() => {
                                // 2023/09/18 张晓瑜新增查询房间是否有未核验资产
            const {info} = await getAssetsverifyApi({apartmentUuid: uuid}).finally(() => loading.close());
            if (info) {
                this.$confirm('该房源存在未核验资产, 请核验后在生成合同', '注意', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    //查询房间是否绑定房型
                    this.getApartmentList()
                }).catch(() => {
                });
            } else {
                           // 2023/09/18 张晓瑜新增查询房间是否有未核验资产
            const {info} = await getAssetsverifyApi({apartmentUuid: uuid}).finally(() => loading.close());
            if (info) {
                this.$confirm('该房源存在未核验资产, 请核验后在生成合同', '注意', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    //查询房间是否绑定房型
                    this.getApartmentList()
                }).catch(() => {
                });
            } else {
                //查询房间是否绑定房型
                this.getApartmentList()
            }
            }
                });
            } else {
                //查询房间是否绑定房型
                this.getApartmentList()
            }

        },

        //查询房间是否绑定房型
        getApartmentList() {
            const that = this;
            const {apartmentUuid, address} = that.currentHouse;
            const uuid = that.zUuid || apartmentUuid;
            const loadingOptions = that.loadingOptions;
            let loading = that.$loading({...loadingOptions});
            //查询房间是否绑定房型
            getApartmentData(uuid).then((res) => {
                //是保租房直接打开添加合同弹窗
                if (that.isbzf) {
                    that.$refs["dialogAddContract"].openDialog();
                } else {
                    //不是保租房提醒抄表，点击确定打开添加合同弹窗，点击取消，跳转智能管控
                    that.$confirm("添加租房合同前需要抄表，是否已抄表?", "注意", {type: "warning"}).then(() => {
                        this.$refs["dialogAddContract"].openDialog();
                    }).catch(() => {
                        this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                    });
                }
            }).finally(() => loading.close());
        },

        //打开添加合同附件弹窗
        saveFile(data) {
            this.$refs["dialogSaveContractFile"].openDialog(data);
        },

        //打开浏览合同附件弹窗
        lookFile(data) {
            this.$refs["dialogLookContractFile"].openDialog(data);
        },

        //点击退房事件
        tuifang(data) {
            console.log('111');
            const {address} = this.currentHouse;
            //查询房间是否是保租房
            //是保租房直接打开退房弹窗
            if (this.isbzf) {
                this.$refs["dialogCheckOut"].openDialog(data);
            } else {
                //不是保租房提醒抄表，点击确定打开退房弹窗，点击取消，跳转智能管控
                this.$confirm("此操作前需要抄表，是否已抄表?", "注意", {type: "warning"}).then(() => {
                    this.$refs["dialogCheckOut"].openDialog(data);
                }).catch(() => {
                    this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
                });
            }
        },

        //点击续租事件
        xuqian(data) {
            //调用组件方法，打开续租弹框
            this.$refs["dialogContractXuqian"].openDialog(data);
        },

        //点击换房事件
        huanFang(data) {
            console.log('bhihp');
          this.$router.push({name: "Home", params: {data: {apartmerntName: "checkOut"}}});
          // this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
            // const {address} = this.currentHouse;
            // //查询房间是否是保租房
            // //是保租房直接打开换房弹窗
            // if (this.isbzf) {
            //     this.$refs["dialogExchangeHouses"].openDialog(data);
            // } else {
            //     //不是保租房提醒抄表，点击确定打开换房弹窗，点击取消，跳转智能管控
            //     this.$confirm("此操作前需要抄表，是否已抄表?", "注意", {type: "warning"}).then(() => {
            //         this.$refs["dialogExchangeHouses"].openDialog(data);
            //     }).catch(() => {
            //         this.$router.push({name: "door-management", params: {data: {apartmerntName: address}}});
            //     });
            // }
        },

        //点击同住人事件
        tongzhuren(data, type) {
            //调用组件方法，打开同住人弹框
            this.$refs["dialogTongzhurenList"].openDialog(data, type);
        },

        //点击查看账单事件
        lookBills(data) {
            //调用组件方法，打开查看账单弹框
            this.$refs["dialogLookZhangdan"].openDialog(data);
        },

        // 张晓瑜新增合同备案
        contractFiling(data) {
            //调用组件方法，打开新增合同备案弹框
            this.$refs["dialogContractFiling"].openDialog(data);
        },

        //合同备注编辑
        editComment(data) {
            let {uuid, comment} = data;
            this.formPublish.uuid = uuid;
            this.formPublish.comment = comment;
            //打开合同备注弹框
            this.dialogVisibleComment = true;
        },

        //合同备注编辑提交
        clickSubmit() {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            const {uuid, comment} = that.formPublish;
            editComment({uuid, comment}).then((res) => {
                MessageSuccess("合同备注编辑成功");
                that.getContractDetailData(uuid);
                that.clickCancel();
            }).finally(() => loading.close());
        },

        //合同备注编辑关闭
        clickCancel() {
            this.dialogVisibleComment = false;
            this.$refs["formPublish"].resetFields();
        },

        //点击催收事件
        collection({uuid}) {
            this.collectionData.uuid = uuid;
            //打开催收弹框
            this.collectionDialogVisible = true;
        },

        //催收确认
        collectionClickSubmit() {
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            let {uuid, type} = that.collectionData;
            messageSend(uuid, type).then((res) => {
                MessageSuccess("催收成功");
                that.collectionClickCancel();
            }).finally(() => loading.close());
        },

        //催收取消
        collectionClickCancel() {
            this.collectionData.uuid = null;
            this.collectionData.type = 0;
            this.collectionDialogVisible = false;
        },

        //点击完善合同事件
        perfectTheContract(data) {
            // 给contractUuid赋值uuid
            data.contractUuid = data.uuid;
            //保存数据到localStorage
            localStorage.setItem("paramData", JSON.stringify(data));
            //新建路由
            const newWindows = this.$router.resolve({path: "/test-canvas"});
            //新界面打开
            window.open(newWindows.href, "_blank");
        },

        // base64字符串转pdf类型Bolb对象
        base64ToBlob(code) {
            //Base64一行不能超过76字符，超过则添加回车换行符。因此需要把base64字段中的换行符，回车符给去掉，有时候因为存在需要把加号空格之类的换回来，取决于base64存取时的规则。
            code = code.replace(/[\n\r]/g, "");
            var raw = window.atob(code);
            let rawLength = raw.length;
            //转换成pdf.js能直接解析的Uint8Array类型
            let uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: "application/pdf"}); //转成pdf类型
        },

        //e签宝电子合同重新签署
        contractSign(uuid) {
            let that = this;
            this.$confirm("是否重新签署当前电子合同?", "注意", {type: "warning"}).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                //调用接口
                contractSign(uuid).then((res) => {
                    let {info: {data},} = res;
                    if (data) {
                        //获取签署地址
                        let {url} = data;
                        //新界面打开
                        let tempwindow = window.open("", "_blank");
                        //新界面更换地址
                        tempwindow.location = url;
                    }
                    MessageSuccess("生成合同成功");
                    //刷新合同详情
                    that.getContractDetailData(uuid);
                }).finally(() => loading.close());
            }).catch(() => {});
        },

        //诺诺电子合同重新签署
        bzfInvalid(uuid) {
            let that = this;
            this.$confirm("是否重新签署当前电子合同?", "注意", {type: "warning"}).then(() => {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                //调用接口
                bzfInvalid(uuid).then((res) => {
                    // 给contractUuid赋值uuid
                    let paramData = {contractUuid: uuid, uuid};
                    //保存数据到localStorage
                    localStorage.setItem("paramData", JSON.stringify(paramData));
                    //新建路由
                    const newWindows = this.$router.resolve({path: "/test-canvas"});
                    //新界面打开
                    window.open(newWindows.href, "_blank");
                    //刷新合同详情
                    that.getContractDetailData(uuid);
                }).finally(() => loading.close());
            }).catch(() => {
            });
        },
        // 身份证展示
        immgshow(e) {
            this.$refs.imgref.dialogVisible = true;
            this.$refs.imgref.srcimg = e;
        },
    },
    watch: {
        async zUuid(value) { // 子房间uuid变动时，查询当前房间有没有被出租
            const {apartmentUuid} = this.currentHouse;
            let uuid = this.zUuid || apartmentUuid;
            let {info} = await contractIsSign(uuid);
            this.isSign = info;
        },
    },
};
</script>

<style lang="scss" scoped>
.layer-contract-detail {
    .el-descriptions {
        margin-bottom: VH(30px);
    }

    .title {
        position: relative;
        padding-left: 10px;

        &::before {
            width: 5px;
            height: 100%;
            content: "";
            background-color: #f5a623;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    font-size: 12px;
}

.el-icon-arrow-down {
    font-size: 12px;
}
</style>
