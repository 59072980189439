<template>
    <section class="detail">
        <r-e-descriptions :column="2" :schemes="houseDetailSchemas" :data="houseInfo"
                          :labelStyle="{ width: '80px', textAlign: 'right', display: 'inline-block', }">
            <div class="title" slot="title">房间信息</div>
        </r-e-descriptions>

        <!--<el-button type="primary" @click="dialogVisible=true" size="small">设置</el-button>-->
        <el-button type="primary" size="small" @click="editRent">编辑租金</el-button>
        <el-button type="primary" size="small" @click="addZiFangJian" v-if="!zUuid">添加子房间</el-button>
        <el-button type="primary" size="small" @click="openVerification">房间核验</el-button>
        <el-button type="primary" size="small" @click="openVerificationCode">核验码核验</el-button>
       <el-button type="primary" size="small" @click="updateApartmentDetailClick">修改房间信息</el-button>
        <r-e-descriptions :column="3" :schemes="lesseeDetailSchemas" :data="houseInfo"
                          :labelStyle="{ width: '80px', textAlign: 'right', display: 'inline-block', }">
            <div class="title" slot="title">出租情况</div>
        </r-e-descriptions>

        <!--   设置房间金额       -->
        <r-e-dialog title="编辑房间金额" :visible="amountIsShow" show-footer width="600px"
                    @click-submit="handleSubmitUpdate" @click-cancel="handleCancel" @close="handleCancel">
            <el-form ref="apartmentAmount" label-width="110px" size="small" :model="apartmentAmount"
                     :rules="apartmentAmountRules">
                <el-form-item label="地址" prop="name">
                    <el-input disabled v-model="apartmentAmount.name"/>
                </el-form-item>
                <el-form-item label="房型" prop="publishUuid">
                    <el-select v-model="apartmentAmount.publishUuid" placeholder="请选择房型" style="width: 100%">
                        <el-option v-for="item in publishList" :key="item.uuid" :label="item.templateName"
                                   :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="月租金(元/月)" prop="monthAmount">
                    <el-input type="number" v-model="apartmentAmount.monthAmount" placeholder="请输入月租金"/>
                </el-form-item>
                <el-form-item label="季租金(元/月)" prop="seasonAmount">
                    <el-input type="number" v-model="apartmentAmount.seasonAmount" placeholder="请输入季租金"/>
                </el-form-item>
                <el-form-item label="半年租金(元/月)" prop="halfAmount">
                    <el-input type="number" v-model="apartmentAmount.halfAmount" placeholder="请输入半年租金"/>
                </el-form-item>
                <el-form-item label="年租金(元/月)" prop="yearAmount">
                    <el-input type="number" v-model="apartmentAmount.yearAmount" placeholder="请输入年租金"/>
                </el-form-item>
                <el-form-item label="押金(元/月)" prop="bone">
                    <el-input type="number" v-model="apartmentAmount.bone" placeholder="请输入押金"/>
                </el-form-item>
                <el-form-item label="定金(元/月)" prop="deposit">
                    <el-input type="number" v-model="apartmentAmount.deposit" placeholder="请输入定金"/>
                </el-form-item>
                <el-form-item label="服务费(元/月)" prop="tipAmount">
                    <el-input type="number" v-model="apartmentAmount.tipAmount" placeholder="请输入服务费"/>
                </el-form-item>
            </el-form>
        </r-e-dialog>

        <r-e-dialog title="添加子房间" :visible="ziFangJianIsShow" show-footer width="600px"
                    @click-submit="handleAddZiFangJianIsSubmit" @click-cancel="clickAddZiFangJianCancel"
                    @close="clickAddZiFangJianCancel">
            <el-form ref="ziFangJianRef" label-width="90px" size="small" :model="ziFangJianData"
                     :rules="ziFangJianRules">
                <el-form-item label="子房间名" prop="name">
                    <el-input disabled v-model="houseInfo.address" style="width: 200px"/>
                    &nbsp;&nbsp;
                    <el-input v-model="ziFangJianData.name" style="width: 200px"/>
                </el-form-item>
            </el-form>
        </r-e-dialog>

        <dialog-edit-detail-bzf-unit-price ref="dialogEditDetailBzfUnitPrice" @handleSearch="handleSearch"/>

        <dialog-verification ref="dialogVerification" :currentHouse="currentHouse"/>

        <dialog-verification-code ref="dialogVerificationCode" :currentHouse="currentHouse"/>

        <!--        <r-e-dialog title="设置优惠价" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
                            width="550px" @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
                    <el-form ref="formPublish" label-width="90px" size="small" :model="formPublish" :rules="rules">
                        <div class="flex">
                            <el-form-item label="房源信息">
                                <el-input v-model="currentHouse.address" disabled placeholder="请填写房型名称（15个字以内）" style="width: 400px;" />
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <el-form-item label="原价">
                                <el-input v-model="displayAmount" disabled placeholder="请填写原价" style="width: 400px;" />
                            </el-form-item>
                        </div>
                        <div class="flex">
                            <el-form-item label="优惠价格" prop="amount">
                                <el-input-number v-model="formPublish.amount" :precision="2" :step="1.00" :min="1" style="width: 400px;" placeholder="请输入优惠价格"/>
                            </el-form-item>
                        </div>
                    </el-form>
                </r-e-dialog>-->
        <!-- 修改房间信息 -->

        <layer-add-house title="修改房源" ref="layerHouseRef" @handleSearch="handleSearch"/>
    </section>
</template>

<script>
//房间详情
import {
    houseDetailSchemas, houseDetailSchemasFalse, lesseeDetailSchemas,
} from "@/views/rental-management/house-resource/data";
import {
    getHouseDetailApi, apartmentAmountUpdate, getApartmentDetails, apartmentSplit, isbzf,
} from "@/api/house-resource";
import {getPublishList} from "@/api/publish";
import {MessageSuccess} from "@custom/message";
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import layerAddHouse from '../layer-add-house.vue'

export default {
    name: "detail",
    data() {
        return {
            houseDetailSchemas,
            lesseeDetailSchemas,
            houseInfo: {},
            formPublish: {},
            dialogVisible: false,
            rules: {
                amount: [{required: true, message: "请输入优惠价格", trigger: "blur"},],
            },
            displayAmount: 0,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            },
            amountIsShow: false,
            apartmentAmount: {
                name: null,
                publishUuid: null,
                bone: null,
                deposit: null,
                tipAmount: null,
                monthAmount: null,
                seasonAmount: null,
                halfAmount: null,
                yearAmount: null,
            },
            apartmentAmountRules: {
                bone: [{required: true, message: "请输入押金", trigger: "blur"}],
                deposit: [{required: true, message: "请输入定金", trigger: "blur"}],
                tipAmount: [{required: true, message: "请输入服务费", trigger: "blur"},],
                monthAmount: [{required: true, message: "请输入月租金", trigger: "blur"},],
                seasonAmount: [{required: true, message: "请输入季租金", trigger: "blur"},],
                halfAmount: [{required: true, message: "请输入半年租金", trigger: "blur"},],
                yearAmount: [{required: true, message: "请输入年租金", trigger: "blur"},],
            },
            publishList: [],
            ziFangJianIsShow: false,
            ziFangJianData: {
                uuid: null,
                name: null,
            },
            ziFangJianRules: {
                name: [{required: true, message: "请输入子房间名", trigger: "blur"}],
            },
        };
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({}),
        },
        zUuid: {
            type: String,
            default: null,
        },
    },
    components: {
        dialogEditDetailBzfUnitPrice: () => import("./components/dialog-edit-detail-bzf-unit-price"),
        dialogVerification: () => import("./components/dialog-verification"),
        dialogVerificationCode: () => import("./components/dialog-verification-code"),
        layerAddHouse,
    },
  // async created() {
  //   const loadingOptions = this.loadingOptions;
  //   const loading = this.$loading({...loadingOptions});
  //   let {list} = await getPublishList();
  //   console.log(list,'listlistlistlistlistlist')
  //   this.publishList = list;
  //   loading.close();
  // },
  async mounted() {
      const loadingOptions = this.loadingOptions;
      const loading = this.$loading({...loadingOptions});
        // 获取房源UUID
        const {apartmentUuid} = this.currentHouse;
        let uuid = this.zUuid || apartmentUuid;
        // 陈守亮-2023-10-23-保租房和富航进行相对应的展示
        let itr = await isbzf(uuid);
        if (itr.info === false) {
            this.houseDetailSchemas = houseDetailSchemasFalse;
        } else {
            this.houseDetailSchemas = houseDetailSchemas;
        }
        //获取房间信息
        const {info = {}} = await getHouseDetailApi(uuid);
        this.houseInfo = info;
        this.displayAmount = ParseFloatFormat(info.displayAmount); //this.houseInfo.displayAmount / 100;
        //获取所有房型

        loading.close();
    },

    methods: {

        //点击编辑租金事件
        async editRent() {
            let that = this;
            // that.$refs["apartmentAmount"].resetFields();
            const {apartmentUuid} = this.currentHouse;
            let uuid = that.zUuid || apartmentUuid;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});

            //获取房间金额信息
            let {info} = await getApartmentDetails(uuid);
            let {
                bone, deposit, tipAmount, monthAmount, seasonAmount, halfAmount, yearAmount, publishUuid, name,
            } = info;
            //转换金额
            bone = ParseFloatFormat(bone);
            deposit = ParseFloatFormat(deposit);
            tipAmount = ParseFloatFormat(tipAmount);
            monthAmount = ParseFloatFormat(monthAmount);
            seasonAmount = ParseFloatFormat(seasonAmount);
            halfAmount = ParseFloatFormat(halfAmount);
            yearAmount = ParseFloatFormat(yearAmount);
            that.apartmentAmount = {
                bone, deposit, tipAmount, monthAmount, seasonAmount, halfAmount, yearAmount, publishUuid, name,
            };
            //查询房间是否是保租房
            let re = await isbzf(uuid);

            //根据是否是保租房，打开不同弹框
            if (!re.info) {
              let {list} = await getPublishList();
                that.$refs["dialogEditDetailBzfUnitPrice"].openDialog({uuid, publishUuid, name}, list);
            } else that.amountIsShow = true;
            loading.close();
        },

        //富航 编辑房间金额 确认事件
        handleSubmitUpdate() {
            let that = this;
            //表单校验
            that.$refs["apartmentAmount"].validate((valid) => {
                if (valid) {
                    const {apartmentUuid} = that.currentHouse;
                    //拆分数据
                    let {
                        bone, deposit, tipAmount, monthAmount, seasonAmount, halfAmount, yearAmount, publishUuid,
                    } = that.apartmentAmount;
                    //金额转换
                    bone = ParseIntFormat(bone);
                    deposit = ParseIntFormat(deposit);
                    tipAmount = ParseIntFormat(tipAmount);
                    monthAmount = ParseIntFormat(monthAmount);
                    seasonAmount = ParseIntFormat(seasonAmount);
                    halfAmount = ParseIntFormat(halfAmount);
                    yearAmount = ParseIntFormat(yearAmount);

                    //封装数据
                    const paramData = {
                        apartmentUuid: that.zUuid || apartmentUuid, bone, deposit, tipAmount, monthAmount, seasonAmount,
                        halfAmount, yearAmount, publishUuid,
                    };

                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});

                    //调用接口，上传数据
                    apartmentAmountUpdate(paramData).then((res) => {
                        MessageSuccess("编辑成功");
                        that.handleCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        //富航 编辑房间金额 取消事件
        handleCancel() {
            this.$refs["apartmentAmount"].resetFields();
            this.amountIsShow = false;
            this.handleSearch();
        },

        //点击添加子房间事件
        addZiFangJian() {
            //打开添加子房间弹窗
            this.ziFangJianIsShow = true;
            let {apartmentUuid} = this.currentHouse;
            // 子房间对象uuid赋值当前房间uuid
            this.ziFangJianData.uuid = apartmentUuid;
        },

        //添加子房间 确认事件
        handleAddZiFangJianIsSubmit() {
            let that = this;
            that.$refs["ziFangJianRef"].validate((valid) => {
                if (valid) {
                    //拆分数据
                    const {uuid, name} = that.ziFangJianData;
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    //打包数据
                    const paramData = {uuid, name};
                    //调用接口上传数据
                    apartmentSplit(paramData).then((res) => {
                        MessageSuccess("添加成功");
                        that.$emit("apartmentSon", uuid);
                        that.clickAddZiFangJianCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        //添加子房间 取消事件
        clickAddZiFangJianCancel() {
            this.ziFangJianIsShow = false;
            this.ziFangJianData.uuid = null;
            this.ziFangJianData.name = null;
        },

        //刷新房间信息
        async handleSearch() {
            let that = this;
            const {apartmentUuid} = this.currentHouse;
            const uuid = that.zUuid || apartmentUuid;
            //调用接口刷新房间信息
            const {info = {}} = await getHouseDetailApi(uuid);
            this.houseInfo = info;
            this.$emit("handleSearch");
        },

        //点击房间核验事件
        openVerification() {
            //调用组件方法，打开房间核验弹框
            this.$refs["dialogVerification"].openDialog();
        },
        //点击核验码核验事件
        openVerificationCode() {
            //调用组件方法，打开核验码核验弹框
            this.$refs["dialogVerificationCode"].openDialog();
        },

        /*clickSubmit() {
            let that = this;
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const {apartmentUuid} = that.currentHouse;
                    let {amount} = that.formPublish;
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    let paramData = {uuid: apartmentUuid, amount: amount * 100};

                    setApartmentAmount(paramData).then(res => {
                        MessageSuccess('设置成功');
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },

        async clickCancel() {
            this.dialogVisible = false;
            // 获取房源UUID
            const {apartmentUuid} = this.currentHouse;

            const {info = {}} = await getHouseDetailApi(apartmentUuid);
            this.houseInfo = info;
        },*/
        // 修改房源信息
        updateApartmentDetailClick() {
            const {apartmentUuid} = this.currentHouse;
          console.log(apartmentUuid)
            const uuid = this.zUuid || apartmentUuid;
            this.$refs.layerHouseRef.layerAddHouseInit(null,uuid);
        }
    },
};
</script>

<style lang="scss" scoped>
.detail {
    .el-descriptions {
        margin: 20px 0 50px;
    }

    .title {
        position: relative;
        padding-left: 10px;

        &::before {
            width: 5px;
            height: 100%;
            content: "";
            background-color: #f5a623;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
</style>
